import { RefObject, useCallback, useRef } from 'react';
import { shallow } from 'zustand/shallow';

import { killTimeline } from '~/utils';

import {
  closeDesktopNavSubNavAnimation,
  hideDesktopLinksAnimation,
  openDesktopNavSubNavAnimation,
  revealDesktopLinksAnimation,
} from '../Navigation.animations';
import useNavState from '../Navigation.state';
import {
  closeMobileNavSubNavAnimation,
  openMobileNavSubNavAnimation,
} from '../NavMobile/NavMobile.animations';
import useMainNavActiveItemDelay from './useMainNavActiveItemDelay';

const useSubNav = ({
  id,
  $mobileSubNavLinks,
  $desktopSubNavLinkContainers,
  $categoryHeader,
  $subCategoryEyebrows,
  subNavHeight,
}: {
  id: string;
  $mobileSubNavLinks: RefObject<HTMLElement[]>;
  $desktopSubNavLinkContainers: RefObject<HTMLElement[][]>;
  $categoryHeader: RefObject<HTMLHeadingElement>;
  $subCategoryEyebrows: RefObject<HTMLHeadingElement[]>;
  subNavHeight: RefObject<number>;
}) => {
  const openSubNavAnimation = useRef<GSAPTimeline>();
  const closeSubNavAnimation = useRef<GSAPTimeline>();
  const revealLinksAnimation = useRef<GSAPTimeline>();
  const hideLinksAnimation = useRef<GSAPTimeline>();

  const {
    setActiveMainNavItemImmediately,
    setActiveMainNavItemToCurrentPageImmediately,
    setIsHoveringMainNavItemImmediately,
  } = useMainNavActiveItemDelay();

  const [
    $backdropEl,
    $shadowEl,
    $mobileMainNavCtaItems,
    $mobileBottomLinksWrapper,
    currentOpenedSubNav,
    setCurrentOpenedSubNav,
    setPreviousSubNav,
    setNextSubNav,
    isMobileNav,
  ] = useNavState(
    (state) => [
      state.$backdropEl,
      state.$shadowEl,
      state.$mobileMainNavCtaItems,
      state.$mobileBottomLinksWrapper,
      state.currentOpenedSubNav,
      state.setCurrentOpenedSubNav,
      state.setPreviousSubNav,
      state.setNextSubNav,
      state.isMobileNav,
    ],
    shallow,
  );

  const openSubNav = useCallback(() => {
    setCurrentOpenedSubNav(id);

    // cancel the close animation
    if (closeSubNavAnimation.current)
      killTimeline(closeSubNavAnimation.current, false);

    if (isMobileNav) {
      if ($mobileMainNavCtaItems && $mobileBottomLinksWrapper) {
        openSubNavAnimation.current = openMobileNavSubNavAnimation({
          $mobileMainNavCtaItems,
          $mobileBottomLinksWrapper,
          $mobileSubNavLinks,
        });
      }
    } else {
      if ($backdropEl && $shadowEl && subNavHeight) {
        revealLinksAnimation.current;
        const openDesktopNavSubNavAnimationTls = openDesktopNavSubNavAnimation({
          $desktopSubNavLinkContainers,
          $categoryHeader,
          $subCategoryEyebrows,
          $backdropEl,
          $shadowEl,
          subNavHeight,
        });

        openSubNavAnimation.current =
          openDesktopNavSubNavAnimationTls.navReveal;
        revealLinksAnimation.current =
          openDesktopNavSubNavAnimationTls.subNavReaveal;
      }
    }
    openSubNavAnimation.current?.play();
  }, [
    id,
    setCurrentOpenedSubNav,
    $desktopSubNavLinkContainers,
    $categoryHeader,
    $subCategoryEyebrows,
    $backdropEl,
    subNavHeight,
    $mobileSubNavLinks,
    $shadowEl,
    isMobileNav,
    $mobileBottomLinksWrapper,
    $mobileMainNavCtaItems,
  ]);

  const cancelOpenAnimation = () => {
    // cancel the open animation
    if (revealLinksAnimation.current) {
      killTimeline(revealLinksAnimation.current, false);
    }
  };
  const closeSubNav = useCallback(() => {
    setCurrentOpenedSubNav(null);

    cancelOpenAnimation();

    if (isMobileNav) {
      setCurrentOpenedSubNav(null);
      if ($mobileMainNavCtaItems && $mobileBottomLinksWrapper)
        closeSubNavAnimation.current = closeMobileNavSubNavAnimation({
          $mobileMainNavCtaItems,
          $mobileBottomLinksWrapper,
          $mobileSubNavLinks,
        });
    } else {
      if ($backdropEl && $shadowEl && subNavHeight) {
        closeSubNavAnimation.current = closeDesktopNavSubNavAnimation({
          $desktopSubNavLinkContainers,
          $categoryHeader,
          $subCategoryEyebrows,
          $backdropEl,
          $shadowEl,
          subNavHeight: subNavHeight,
          onComplete: () => {
            setActiveMainNavItemToCurrentPageImmediately();
            setIsHoveringMainNavItemImmediately(false);
          },
        });
      }
    }

    closeSubNavAnimation.current?.play();
  }, [
    setCurrentOpenedSubNav,
    $desktopSubNavLinkContainers,
    $categoryHeader,
    $subCategoryEyebrows,
    $backdropEl,
    $shadowEl,
    isMobileNav,
    $mobileBottomLinksWrapper,
    $mobileMainNavCtaItems,
    setActiveMainNavItemToCurrentPageImmediately,
    setIsHoveringMainNavItemImmediately,
    subNavHeight,
    $mobileSubNavLinks,
  ]);

  const hidePreviousSubNavLinks = () => {
    // cancel the open animation as it would potentially conflict with this animation
    cancelOpenAnimation();
    if (revealLinksAnimation.current?.isActive())
      killTimeline(revealLinksAnimation.current, false);
    hideLinksAnimation.current = hideDesktopLinksAnimation({
      $desktopSubNavLinkContainers,
      $categoryHeader,
      $subCategoryEyebrows,
      direction: 1,
    });

    hideLinksAnimation.current.play();
  };

  const showNextSubNavLinks = useCallback(() => {
    // cancel the open animation has it would potentially conflict with this animation
    setCurrentOpenedSubNav(id);
    cancelOpenAnimation();
    if (hideLinksAnimation.current?.isActive())
      killTimeline(hideLinksAnimation.current, false);
    revealLinksAnimation.current = revealDesktopLinksAnimation({
      $desktopSubNavLinkContainers,
      $categoryHeader,
      $subCategoryEyebrows,
      onStart: () => {
        setPreviousSubNav(null);
        setNextSubNav(null);
      },
    });

    revealLinksAnimation.current.play();
  }, []);

  const showOrHideSubNav = useCallback(() => {
    // if none of the subnavs are currently open
    if (!currentOpenedSubNav) {
      openSubNav();
      setActiveMainNavItemImmediately(id);
      // play the open animation
    } else if (currentOpenedSubNav === id) {
      // if clicking a subnav that's currently open, toggle it closed
      closeSubNav();
    }
  }, [
    id,
    currentOpenedSubNav,
    closeSubNav,
    setActiveMainNavItemImmediately,
    openSubNav,
  ]);

  const swapSubNav = useCallback(() => {
    // if clicking to open a subnav that's different than the one already open
    // set the main nav item to active
    setActiveMainNavItemImmediately(id);
    setPreviousSubNav(currentOpenedSubNav);
    setNextSubNav(id);
  }, [
    id,
    currentOpenedSubNav,
    setActiveMainNavItemImmediately,
    setPreviousSubNav,
    setNextSubNav,
  ]);

  const closeSubNavAndResetAnimation = useCallback(() => {
    if (openSubNavAnimation.current) killTimeline(openSubNavAnimation.current);
    setCurrentOpenedSubNav(null);
  }, [setCurrentOpenedSubNav]);

  return {
    openSubNav,
    closeSubNav,
    hidePreviousSubNavLinks,
    showNextSubNavLinks,
    showOrHideSubNav,
    swapSubNav,
    closeSubNavAndResetAnimation,
  };
};

export default useSubNav;
